$positive: #f1c40f;
$negative: #8e44ad;

.counter {

  display: inline-block;
  padding: 0 .2em;
  position: relative;
  border-radius: .2em;

  span {
    display: inline !important;
  }

  &.bold {
    font-weight: bold;
  }

  transition: color .5s ease-in, background-color .5s ease-in;
  &.changing {
    &.positive {
      color: $positive !important;
      background-color: rgba($positive,.1) !important;
    }
    &.negative {
      color: $negative !important;
      background-color: rgba($negative,.1) !important;
    }
  }
  .counter-amount {
    position: relative;
  }
  &.stable {
  }

}