@import '../sass/mixins.scss';

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header {
  backdrop-filter: blur(5px);
  border-radius: 1rem;

  h1 {
    margin: 0;
    font-size: 3.5rem;
    color: darken(#2c3e50, 5);
  }

  h2 {
    margin: 0;
    font-size: 2rem;
    color: darken(#2c3e50, 0);

    .mdi-icon {
      @include md-icon-size(2rem);
      vertical-align: -.4rem;
      margin-right: .5rem;
    }
  }

  @media screen and (max-width: 800px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.1rem;

      .mdi-icon {
        @include md-icon-size(1.2rem);
        margin-right: .5rem;
      }
    }
  }
}

main {
  backdrop-filter: blur(5px);
  position: relative;
  background-color: rgba(255, 255, 255, .2);
  width: 45rem;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
  max-width: 70vw;
  @include addShadow(#2c3e50);
}

.your-account {
  font-size: 1rem;
  background-color: rgba(255, 255, 255, .2);
  padding: 0 1rem 1rem 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  h4 {
    margin: 1rem 0 0 0;
    font-weight: normal;
  }

  .amount {
    font-size: 2rem;
    margin: 0;
    font-weight: bold;
    @media screen and (max-width: 800px) {
      font-size: 1.2rem;
      .mdi-icon {
        @include md-icon-size(1.2rem);
        vertical-align: -.1rem;
      }
    }
  }
}

.input {
  position: relative;
  background-color: #fff;
  margin: 2rem 5rem 0 5rem;
  color: #888;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;

  input {
    font-size: 2rem;
    background-color: transparent;
    border: 0;
    padding: 1.5rem .5rem;
    width: 22rem;
    color: #2c3e50;

    &:focus {
      outline: none;
    }
  }

  .token {
    font-size: 1.8rem;
    padding: .5rem 1rem .5rem 2rem;
    width: 5rem;
  }

  .set-max {
    position: absolute;
    right: 1.25rem;
    top: 1.0rem;

    button {
      font-size: 1.2rem;
      cursor: pointer;
      border: 0;
      background-color: transparent;
      color: #34495e;
    }
  }

  @media screen and (max-width: 800px) {
    margin: 1rem 0 0 0;
    border-radius: 1rem;
    .token {
      padding: 0 .25rem 0 1rem;
      font-size: 0.8rem;
    }
    input {
      padding: .75rem .5rem;
      font-size: 1rem;
    }
    .set-max {
      right: .35rem;
      top: .7rem;

      button {
        font-size: .75rem;
      }
    }
  }
}

.set-max {
  margin: 0;
}

.to-amount {
  min-width: 33.25rem;
  background-color: rgba(255, 255, 255, .25);
  padding: 1rem;
  display: inline-block;
  border-radius: 1.5rem;
  @media screen and (max-width: 800px) {
    border-radius: 1rem;
    min-width: 0;
    padding: .75rem;
    display: block;
  }
}

.toggle-direction {
  border-radius: 50%;
  height: 3.5rem;
  width: 3.5rem;
  margin: .5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: .15s ease-out;

  button {
    width: 100%;
    height: 100%;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .5);

    .mdi-icon {
      @include md-icon-size(2.2rem);
    }
  }

  &:hover, &:focus {
    background-color: rgba(255, 255, 255, .8);
    @include addGlow(#fff);

    button {
      .mdi-icon {
        fill: #9b59b6;
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: 2.5rem;
    width: 2.5rem;
    button {
      .mdi-icon {
        @include md-icon-size(1.5rem);
      }
    }
  }
}

.make-transaction {
  border-radius: 2rem;
  margin: 2rem auto 1rem auto;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: .15s ease-out;

  button {
    width: 100%;
    height: 100%;
    border: 0;
    background-color: rgba(255, 255, 255, .5);
    padding: 1rem;
    font-size: 1.75rem;
    color: darken(#8e44ad, 25);
    &:enabled {
      cursor: pointer;
    }
    &:disabled {
      opacity: .25;
    }
    .mdi-icon {
      @include md-icon-size(2rem);
      transition: .15s ease-out;
      margin-right: .5rem;
    }
  }

  &:hover, &:focus {
    &:not(.disabled){
      background-color: rgba(255, 255, 255, .8);
      @include addGlow(#fff);

      button:enabled {
        color: #9b59b6;

        .mdi-icon {
          transform: scale(1.2);
          animation: example infinite ease-out .5s;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 0;
    border-radius: 1rem;
    button {
      padding: .5rem;
      font-size: 1rem;

      .mdi-icon {
        @include md-icon-size(1.2rem);
        margin-right: .5rem;
      }
    }
  }
}

.connect-button {
  border-radius: 1rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: .15s ease-out;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    cursor: pointer;
    background-color: rgba(255, 255, 255, .5);
    padding: 1rem;
    font-size: 1.5rem;
    color: darken(#2c3e50, 5);

    img {
      height: 3rem;
      margin-right: .5rem;
    }
  }

  &:hover, &:focus {
    &:not(:disabled) {
      background-color: rgba(255, 255, 255, .8);
      @include addGlow(#fff);

      button {
        color: #34495e;
      }
    }
  }
}

.big-hammer {
  display: none;
  @include md-icon-size(50rem);
  //fill: mix(#e67e22, #9b59b6, 50);
  fill: url(#hammer-gradient) #fff;
}

footer {
  backdrop-filter: blur(5px);
  margin-top: 1rem;
  font-size: 1rem;
  div {
    margin-top: .25rem;
  }
  @media screen and (max-width: 800px) {
    font-size: .8rem;
  }
}

@keyframes example {
  0% {
    transform: scale(1.2) rotate(0);
  }
  25% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-25deg);
  }
}

.insufficient-amount {
  margin-top: 2rem;
  margin-bottom: -2rem;
  font-size: 1.2rem;
  .mdi-icon {
    @include md-icon-size(2.5rem);
  }
  @media screen and (max-width: 800px) {
    margin-top: 1rem;
    .mdi-icon {
      @include md-icon-size(2rem);
    }
  }
}