@import '../../sass/mixins.scss';

.loader.circle {

  display: inline-block;
  animation: rotationAnimation linear .7s infinite, fadeInAnimation ease-in 1s;
  height: 5rem;
  width: 5rem;
  border-top: 0.25rem solid rgba(0,0,0,.1);
  border-left: 0.25rem solid rgba(0,0,0,.1);
  border-bottom: 0.25rem solid lighten(mix(#e67e22, #9b59b6, 50), 10);
  border-right: 0.25rem solid rgba(0,0,0,.1);
  border-radius: 50%;
  vertical-align: middle;
  margin: 1rem;
  & ~ .loader-circle {
    // only display first loader on page
    display: none;
  }
}

@keyframes rotationAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}