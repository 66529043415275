@import '../../sass/mixins.scss';

.transaction-error {
  .mdi-icon {
    @include md-icon-size(5rem);
    margin-bottom: .85rem;
    fill: lighten(#e67e22, 15);
  }
  h2 {
    font-size: 1.8rem;
    color: darken(#8e44ad, 20);
  }
  p {
    background-color: lighten(#e74c3c, 35);
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    margin-bottom: 0;
  }
}