@import '../../sass/mixins.scss';

.transaction {
  background-color: lighten(#e74c3c, 35);
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 1rem;
  margin-bottom: 0;
  .hash {
    font-size: 1.2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .link {
    white-space: nowrap;
    a {
      color: darken(#8e44ad, 10);
      font-size: .8rem;
      .mdi-icon {
        @include md-icon-size(.8rem);
        vertical-align: -.075rem;
      }
    }
  }
}