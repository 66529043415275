@mixin md-icon-size($size: 1.5rem) {
  font-size: $size;
  height: $size;
  width: $size;
  vertical-align: #{$size / -4};
}

@mixin addShadow($color: #000000){
  box-shadow: 0 2px 40px -1px rgba($color, .25), 0 2px 12px 0 rgba($color, .5), 0 1px 10px 0 rgba($color, .1);
}

@mixin addShadow2($color: #000000){
  box-shadow: rgb(226, 207, 213) 6px 6px 12px, rgb(247, 242, 244) -12px -12px 24px -2px;
}


@mixin addGlow($color: #fff){
  box-shadow: 0 0 20px 0 rgba($color, .25), 0 0 10px 0 rgba($color, .5), 0 0 10px 0 rgba($color, .1);
}