@import "sass/colors";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom right, #e67e22, #9b59b6);
  color: #2c3e50;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
  text-decoration: none;
  transition: .15s ease-out;
  &:focus, &:hover {
    color: #f1c40f;
  }
}

.web3-warning {
  font-size: 1.5rem;
  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
}