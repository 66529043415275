@import '../../sass/mixins.scss';

.transaction-success {
  & > .mdi-icon {
    @include md-icon-size(5rem);
    margin-bottom: .45rem;
    fill: lighten(#e67e22, 15);
  }
  h2 {
    font-size: 1.8rem;
    color: darken(#8e44ad, 20);
  }
}