@import '../../sass/mixins.scss';

.modal {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .25s ease-out;
  color: #222;

  &.active {
    opacity: 1;
    .modal__container {
      margin-bottom: 20vh;
    }
  }

  &.inactive {
    opacity: 0;
    pointer-events: none;
    .modal__container {
      margin-bottom: 23vh;
    }
  }

  &__overlay {
    position: fixed;
    background-color: rgba(0,0,0,.45);
    width: 100vw;
    height: 100vh;
  }

  &__container {
    position: relative;
    background: #fff;
    padding: 1.25rem 1.5rem;
    border-radius: 1rem;
    transition: .25s ease-out;
    width: 32rem;
    @media screen and (max-width: 500px){
      max-width: 95vw;
    }
    @include addShadow();
    .close-modal-button {
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      padding: 0;
      margin: .45rem;
      background: transparent;
      color: #777;
      .mdi-icon {
        margin: 0;
        @include md-icon-size(2rem)
      }
      &:hover {
        color: #aaa;
      }
      &:focus {
      }
    }
  }

  &__content {
    h2 {
      margin-top: 0;
    }
  }

}